import React, { useEffect, useState } from "react";
import Layout from "../../components/layout/Layout";
import TaskTabs from "../../components/MyTasks/TaskTabs";
import InputFields from "../../components/form/InputField";
import UploadButton from "../../components/common/UploadButton";
import SelectButtonTask from "../../components/MyTasks/SelectButtonTask";
import ReactTable from "../../components/common/ReactTable";
import CustomPagination from "../../components/cms/Pagination/CustumPagination";
import { createColumnHelper } from "@tanstack/react-table";
import Eye from "../../assets/images/eye2.svg";
import searchButton from "../../assets/images/search-normal.svg";
import { Link, useNavigate } from "react-router-dom";
import { axiosUAMInstance } from "../../utils/axios";
import LoaderSpin from "../../components/LoaderSpin";

type TableType = {
  sno: number;
  id: number;
  uniqueId: string;
  competentAuthorityName: string;
  approved: boolean;
  action: boolean;
  status: string;
  approverRelation: any;
  approveTimeStamp: any;
};

const status = [
  { label: "All", value: "" },
  { label: "Approved", value: "APPROVED" },
  { label: "Banned", value: "BANNED" },
  { label: "Rejected", value: "REJECTED" },
  { label: "Transit", value: "TRANSIT" },
  { label: "Refer to regulator", value: "REFER_TO_REGULATOR" },
  { label: "Incomplete", value: "INCOMPLETE" },
  { label: "Pending", value: "PENDING" },
];
const columnHelper = createColumnHelper<TableType>();

const MyTaskCompetentAuthority = () => {
  const [selectedStatus, setSelectedStatus] = useState<string | null>(null);
  const [statusForSearch, setStatusForSearch] = useState<string | null>(null);
  const [searchInput, setSearchInput] = useState<string>("");
  const [loader, setLoader] = useState<boolean>(false);
  const [page, setPage] = useState<number>(1);
  const [pageSize, setPageSize] = useState<number>(10);
  const [total, setTotal] = useState(0);
  const [searchQuery, setSearchQuery] = useState("");
  const [competentData, setCompetentData] = useState([]);
  const navigate = useNavigate();

  const userid = sessionStorage.getItem("userId");

  const apiCall = () => {
    setLoader(true);
    axiosUAMInstance
      .get("/mytask/competent-authority", {
        params: {
          page: page,
          limit: pageSize,
          userId: userid,
          searchText: searchInput,
          status: statusForSearch,
        },
      })
      .then((res: any) => {
        if (res.status === 200) {
          setCompetentData(res?.data?.data?.competentAuthorities);
          setTotal(res?.data?.data?.total);
          // console.log(res, "res");
        }
        setLoader(false);
      })
      .catch((error: any) => {
        console.log(error.message);
        setLoader(false);
      });
  };
  useEffect(() => {
    apiCall();
  }, [pageSize, page]);
  const NavigateCompetent = (
    id: string,
    status: string,
    approverRelation: any,
    approveTimeStamp: any
  ) => {
    navigate("/mytask/authority/form", {
      state: {
        competentId: id,
        status: status,
        approverRelation: approverRelation,
        approveTimeStamp: approveTimeStamp,
      },
    });
  };

  let count: number;
  const serialNoGen = (page: number) => {
    count = (page - 1) * 10;
  };
  serialNoGen(page);

  const columns = [
    columnHelper.accessor("sno", {
      cell: () => {
        while (count <= total) {
          count++;
          return count;
        }
      },
      header: () => <span>S.No.</span>,
    }),
    columnHelper.accessor("uniqueId", {
      cell: (info) => info.renderValue(),
      header: () => <span>Competent Authority ID</span>,
    }),
    columnHelper.accessor("competentAuthorityName", {
      cell: (info) => (info.renderValue() ? info.renderValue() : "N/A"),
      header: () => <span>Competent Authority Name</span>,
    }),
    columnHelper.accessor("status", {
      cell: (info) => info.renderValue(),
      header: () => <span>Status</span>,
    }),
    columnHelper.accessor((row) => row, {
      id: "action",
      cell: (info) => {
        const { uniqueId, status, approverRelation, approveTimeStamp } =
          info.getValue();

        return (
          <div className="flex justify-center items-center">
            {/* <Link to="/mytask/authority/form"> */}
            <div
              onClick={() =>
                NavigateCompetent(
                  uniqueId,
                  status,
                  approverRelation,
                  approveTimeStamp
                )
              }
            >
              <img src={Eye} alt="Eye" className="cursor-pointer" />
            </div>
            {/* </Link> */}
          </div>
        );
      },
      header: () => <span>Action</span>,
    }),
  ];

  const options = [
    { value: "pdf", label: "PDF" },
    { value: "docx", label: "DOCX" },
    { value: "image", label: "Image" },
  ];

  const [selectedOption1, setSelectedOption1] = useState<string | null>(null);
  const [selectedOption2, setSelectedOption2] = useState<string | null>(null);
  const [selectedOption3, setSelectedOption3] = useState<string | null>(null);

  const handleSetOption1 = (value: string) => {
    setSelectedOption1(value);
  };

  const handleSetOption2 = (value: string) => {
    setSelectedOption2(value);
  };

  const handleSetOption3 = (value: string) => {
    setSelectedOption3(value);
  };

  const handleSearchInput = (event: any) => {
    event?.preventDefault();
    const { value } = event?.target;
    setSearchInput(value);
  };

  useEffect(() => {
    if (statusForSearch !== null) {
      setPage(1); // Reset to the first page whenever a new status is selected
      apiCall();
    }
  }, [statusForSearch]);

  const handleSetStatus = (option: any) => {
    setSelectedStatus(option);
    setStatusForSearch(option?.value);
  };

  const handleClickSearch = () => {
    setPage(1);
    apiCall();
  };

  return (
    <div>
      <Layout
        layout={
          <div
            className="relative mx-4 xl:ml-[40px]"
            style={{ minHeight: "calc(100vh - 110px)" }}
          >
            <div className="mt-6 ">
              <TaskTabs />
            </div>
            <div className=" mt-2">
              <div className=" flex  space-x-2  items-center flex-wrap">
                <div className="md:w-[500px] lg:w-[600px] sm:w-[350px] w-[300px]">
                  <div className="mb-2">
                    <label
                      htmlFor="Deposit taker Search"
                      className="text-base font-normal text-gilroy-medium "
                    >
                      Competent authority search
                    </label>
                  </div>

                  <InputFields
                    onChange={handleSearchInput}
                    height="40px"
                    padding="10px"
                    placeholder="Search by Unique ID/name"
                  />
                </div>

                <div className=" flex items-center mt-7">
                  <div className=" w-44">
                    <div
                      className="relative w-full"
                      onClick={handleClickSearch}
                    >
                      <input
                        className={
                          "top-2 left-4 z-10 w-0 h-0 form-input border flex border-gray-300 text-gray-600 rounded-md focus:outline-none justify-between align-middle]"
                        }
                        type="file"
                        id="inputButton"
                      />
                      <button
                        className={
                          " top-0 w-full z-20 bg-[#1c468e] text-white form-input border px-[16px] py-[8px] flex   rounded-[8px] focus:outline-none focus:ring-1 focus:ring-gray-100 justify-center align-middle overflow-hidden"
                        }
                      >
                        <img
                          src={searchButton}
                          alt=""
                          className="mr-1 rounded-lg"
                        />
                        Search
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div>
              <div className="mt-[25px] mb-[35px] ">
                <div className="">
                  <p className="text-sm font-normal text-gilroy-medium ">
                    OR search by Status
                  </p>
                </div>
                <div className="flex items-center flex-wrap gap-4">
                  <div className="z-20">
                    <SelectButtonTask
                      setOption={handleSetStatus}
                      options={status}
                      selectedOption={selectedStatus}
                      placeholder="Status"
                    />
                  </div>
                </div>
              </div>
            </div>
            <div className="h-screen md:h-auto sm:h-auto overflow-x-hidden overflow-y-auto">
              <div className="mb-20">
                {loader ? (
                  <LoaderSpin />
                ) : competentData?.length > 0 ? (
                  <ReactTable defaultData={competentData} columns={columns} />
                ) : (
                  <div className=" flex justify-center items-center">
                    <h1>No data available</h1>
                  </div>
                )}
              </div>
              <div className="mt-10 absolute bottom-0">
                {competentData?.length > 0 && (
                  <CustomPagination
                    currentPage={page}
                    setCurrentPage={setPage}
                    totalItems={total}
                    itemsPerPage={pageSize}
                    maxPageNumbersToShow={5}
                  />
                )}
              </div>
            </div>
          </div>
        }
      />
    </div>
  );
};

export default MyTaskCompetentAuthority;
