import React, { useState, useEffect } from "react";
import { useForm } from "react-hook-form";
import Modal from "@mui/material/Modal";
import { Box } from "@mui/material";
import InputFields from "./InputField";
import ButtonComp from "./ButtonComp";
import add from "../../assets/images/add.svg";
import Swal from "sweetalert2";
import SelectButton from "./SelectButton";
import { axiosUAMInstance } from "../../utils/axios";

// Define the modal styles
const style = {
  position: "absolute" as const,
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  p: 4,
};

// Define form values interface
interface FormValues {
  [key: string]: string;
}

// Define component props interface
interface Props {
  onClose: () => void;
  data: any;
  apiCall?: () => void;
}

// Define option type
interface Option {
  value: string;
  label: string;
  id: number;
}

// Main component
const EntityRegistrationOtpContent: React.FC<Props> = ({
  onClose,
  data,
  apiCall,
}) => {
  const [loader, setLoader] = useState<boolean>(false);
  const [textInputs, setTextInputs] = useState<any[]>([]);
  const [originalData, setOriginalData] = useState<any[]>([]);
  const [imagePreviews, setImagePreviews] = useState<{
    [key: string]: string | undefined;
  }>({});
  const [documentPreviews, setDocumentPreviews] = useState<{
    [key: string]: string | undefined;
  }>({});
  const [selectedKey, setSelectedKey] = useState<Option | null>(null);

  const { register, handleSubmit, setValue } = useForm<FormValues>();

  useEffect(() => {
    fetchData();
  }, [data]);

  // Fetch data and prepare inputs
  const fetchData = async () => {
    setLoader(true);
    try {
      const response = await axiosUAMInstance.get(
        `/websitecontent/content/Home`
      );
      setOriginalData(response.data.data); // Set the original data
      const flattenedData = flattenData(response.data.data, data);
      setTextInputs(flattenedData);
      flattenedData.forEach((input: any) => {
        setValue(input.name, input.text || "");
        setValue(`link-${input.name}`, input.link || "N/A");
        setValue(`img-${input.name}`, input.img || "N/A");
        if (input.keylist) {
          setSelectedKey({
            value: input.keylist,
            label: input.keylist,
            id: input.id,
          });
        }
      });
    } catch (error) {
      console.error("Failed to fetch data:", error);
      Swal.fire("Error", "Failed to load content data.", "error");
    }
    setLoader(false);
  };

  // Flatten data to extract text inputs for editing
  const flattenData = (apiData: any, currentEditData: any) => {
    let flatData: any = [];
    apiData.forEach((item: any) => {
      Object.entries(item.content.updatedStructure.homePageData).forEach(
        ([key, value]: any) => {
          value.forEach((element: any) => {
            if (
              (!currentEditData ||
                element.section === currentEditData.section) &&
              element.id === currentEditData.id
            ) {
              flatData.push({
                name: `${key}_${element.name}_${item.language}`,
                text: element.text,
                link: element.link || "N/A",
                img: element.img || "N/A",
                language: item.language,
                section: element.section,
                id: element.id,
                keylist: element.keylist || "", // Extract keylist if available
              });
            }
          });
        }
      );
    });

    console.log({ flatData });
    return flatData;
  };

  // Handle file/image uploads
  const handleImageChange = async (
    event: React.ChangeEvent<HTMLInputElement>,
    inputName: string,
    isImg: boolean = false
  ) => {
    if (event.target.files && event.target.files.length > 0) {
      const file = event.target.files[0];
      const formData = new FormData();
      formData.append("file", file);

      try {
        const uploadResponse = await axiosUAMInstance.post(
          `/websitecontent/save/file`,
          formData,
          {
            headers: { "Content-Type": "multipart/form-data" },
          }
        );

        if (uploadResponse.data.status === "success") {
          const uploadedFileUrl = uploadResponse.data.data.fileurl;
          if (isImg) {
            setImagePreviews((prev) => ({
              ...prev,
              [inputName]: uploadedFileUrl,
            }));
            setValue(`img-${inputName}`, uploadedFileUrl, {
              shouldDirty: true,
            });
          } else {
            setDocumentPreviews((prev) => ({
              ...prev,
              [inputName]: uploadedFileUrl,
            }));
            setValue(`link-${inputName}`, uploadedFileUrl, {
              shouldDirty: true,
            });
          }
        } else {
          Swal.fire(
            "Upload failed",
            "Failed to upload file. Please try again.",
            "error"
          );
        }
      } catch (error) {
        console.error("Upload Error: ", error);
        Swal.fire("Error", "Error occurred while uploading the file.", "error");
      }
    }
  };

  // Handle form submission
  const handleFormSubmit = async (formData: any) => {
    setLoader(true);
    const updatedInputs = textInputs.map((item) => ({
      ...item,
      text: formData[item.name],
      link: formData[`link-${item.name}`] || "N/A",
      img: formData[`img-${item.name}`] || "N/A",
      keylist:
        item.section === "carousel" ? selectedKey?.value || "" : item.keylist, // Set the selected keylist for carousel
    }));

    // Update original data with the updated inputs
    const updatedOriginalData = updateOriginalData(originalData, updatedInputs);

    try {
      await axiosUAMInstance.post(
        `/websitecontent/update-many`,
        updatedOriginalData // Directly passing the array without wrapping it in an object
      );
      Swal.fire("Success", "Content updated successfully", "success");
      if (apiCall) {
        apiCall();
      }

      onClose();
    } catch (error: any) {
      console.error("Failed to update data:", error);
      Swal.fire("Error", error?.message, "error");
    }
    setLoader(false);
  };

  // Update the original data with new inputs
  const updateOriginalData = (originalData: any, updatedInputs: any) => {
    return originalData.map((item: any) => {
      const updatedHomePageData: any = {};

      // Iterate through each section of the homePageData
      Object.entries(item.content.updatedStructure.homePageData).forEach(
        ([section, elements]: any) => {
          updatedHomePageData[section] = elements.map((element: any) => {
            const inputKey = `${section}_${element.name}_${item.language}`;
            const updatedInput = updatedInputs.find(
              (input: any) =>
                input.name === inputKey &&
                input.id === element.id &&
                input.language === item.language
            );

            // Return the updated element if it exists, otherwise return the original element
            return updatedInput ? { ...element, ...updatedInput } : element;
          });
        }
      );

      // Return the modified item with only the necessary updates
      return {
        ...item,
        content: {
          ...item.content,
          updatedStructure: {
            ...item.content.updatedStructure,
            homePageData: updatedHomePageData,
          },
        },
      };
    });
  };

  const keylist = [
    "totalSchemeUnderLetigation",
    "totalDepositTakerRegistered",
    "totalDepositTakerApproved",
    "totalDepositTakerPending",
    "totalSchemeRegistered",
    "totalSchemeBanned",
  ];

  // Function to convert camelCase text to Title Case with spaces
  function camelCaseToTitleCase(camelCase: string) {
    if (camelCase === null || camelCase === "") {
      return camelCase;
    }
    camelCase = camelCase.trim();
    var newText = "";
    for (var i = 0; i < camelCase.length; i++) {
      if (
        /[A-Z]/.test(camelCase[i]) &&
        i !== 0 &&
        /[a-z]/.test(camelCase[i - 1])
      ) {
        newText += " ";
      }
      newText += camelCase[i];
    }
    return newText
      .replace(/([A-Z])/g, (match) => " " + match)
      .trim()
      .split(" ")
      .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
      .join(" ");
  }

  // Convert static keylist to options with human-readable labels
  const options = keylist.map((key, index) => ({
    id: index + 1,
    value: key,
    label: camelCaseToTitleCase(key).replace("Total ", ""),
  }));

  // console.log(options);

  // Handle key selection
  const handleSetKey = (option: Option) => {
    console.log("Selected Key:", option);
    setSelectedKey(option);
  };

  return (
    <Modal
      open={true}
      onClose={onClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box sx={style}>
        <form
          onSubmit={handleSubmit(handleFormSubmit)}
          className="m-[16px] md:m-[0px] w-[270px] md:w-[544px] rounded-2xl p-[8px] text-gilroy-medium pb-[38px] shadow-xl backdrop-blur-lg bg-white"
        >
          <div
            className="flex flex-row justify-end mb-[12px] cursor-pointer"
            onClick={onClose}
          >
            <img src={add} className="w-6 h-6" alt="close icon" />
          </div>
          <h1 className="mb-[24px] text-center text-black text-[24px] font-normal">
            Edit Content
          </h1>
          <div
            className="custom-scrollbar p-4"
            style={{
              maxHeight: "450px",
              overflowY: "auto",
              overflowX: "hidden",
            }}
          >
            {textInputs.map((input: any, index: number) => (
              <div key={index} className="my-4">
                <label
                  htmlFor={input.name}
                  className="text-black text-[16px] font-normal mb-[8px] text-gilroy-medium"
                >
                  Content:({input.language})
                </label>
                <InputFields
                  {...register(input.name)}
                  className="shadow appearance-none border rounded-2xl h-[61px] mb-[24px] mt-[8px] w-full py-4 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                  placeholder="Type here"
                  defaultValue={input.text}
                />
                {input.section === "carousel" && (
                  <>
                    <label
                      htmlFor={`img-${input.name}`}
                      className="text-black text-[16px] font-normal mb-[8px] text-gilroy-medium"
                    >
                      Upload Image ({input.language}):
                    </label>
                    <input
                      type="file"
                      onChange={(e) => handleImageChange(e, input.name, true)}
                      className="shadow appearance-none border rounded-2xl h-[61px] mb-[24px] mt-[8px] w-full py-4 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                    />
                    {imagePreviews[input.name] && (
                      <img
                        src={imagePreviews[input.name]}
                        alt="Preview"
                        className="mt-4 w-10"
                      />
                    )}
                    <div className="my-4">
                      <label
                        htmlFor="key"
                        className="text-black text-[16px] font-normal mb-[8px] text-gilroy-medium"
                      >
                        Key:
                      </label>
                      <SelectButton
                        setOption={handleSetKey}
                        options={options}
                        selectedOption={selectedKey}
                        placeholder="Select Key"
                      />
                    </div>
                  </>
                )}

                {input.section === "aboutBudsHeadingComp1" && (
                  <>
                    <label
                      htmlFor={`link-${input.name}`}
                      className="text-black text-[16px] font-normal mb-[8px] text-gilroy-medium"
                    >
                      Link ({input.language}):
                    </label>
                    <InputFields
                      {...register(`link-${input.name}`)}
                      className="shadow appearance-none border rounded-2xl h-[61px] mb-[24px] mt-[8px] w-full py-4 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                      defaultValue={input.link}
                      placeholder="No link available"
                      disabled={true}
                    />
                    <label
                      htmlFor={`file-${input.name}`}
                      className="text-black text-[16px] font-normal mb-[8px] text-gilroy-medium"
                    >
                      Upload File ({input.language}):
                    </label>
                    <input
                      type="file"
                      onChange={(e) => handleImageChange(e, input.name)}
                      className="shadow appearance-none border rounded-2xl h-[61px] mb-[24px] mt-[8px] w-full py-4 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                    />

                    {documentPreviews[input.name] && (
                      <img
                        src={documentPreviews[input.name]}
                        alt="Preview"
                        className="mt-4 w-10"
                      />
                    )}
                  </>
                )}
                {input.section === "contactDetails" && (
                  <>
                    <label
                      htmlFor={`link-${input.name}`}
                      className="text-black text-[16px] font-normal mb-[8px] text-gilroy-medium"
                    >
                      Link ({input.language}):
                    </label>
                    <InputFields
                      {...register(`link-${input.name}`)}
                      className="shadow appearance-none border rounded-2xl h-[61px] mb-[24px] mt-[8px] w-full py-4 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                      defaultValue={input.link}
                      placeholder="No link available"
                    />
                    <label
                      htmlFor={`file-${input.name}`}
                      className="text-black text-[16px] font-normal mb-[8px] text-gilroy-medium"
                    >
                      Upload File ({input.language}):
                    </label>
                    <input
                      type="file"
                      onChange={(e) => handleImageChange(e, input.name)}
                      className="shadow appearance-none border rounded-2xl h-[61px] mb-[24px] mt-[8px] w-full py-4 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                    />

                    {documentPreviews[input.name] && (
                      <img
                        src={documentPreviews[input.name]}
                        alt="Preview"
                        className="mt-4 w-10"
                      />
                    )}
                    <label
                      htmlFor={`img-${input.name}`}
                      className="text-black text-[16px] font-normal mb-[8px] text-gilroy-medium"
                    >
                      Upload Image ({input.language}):
                    </label>
                    <input
                      type="file"
                      onChange={(e) => handleImageChange(e, input.name, true)}
                      className="shadow appearance-none border rounded-2xl h-[61px] mb-[24px] mt-[8px] w-full py-4 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                    />
                    {imagePreviews[input.name] && (
                      <img
                        src={imagePreviews[input.name]}
                        alt="Preview"
                        className="mt-4 w-10"
                      />
                    )}
                  </>
                )}
                {input.section === "logo" && (
                  <>
                    <label
                      htmlFor={`link-${input.name}`}
                      className="text-black text-[16px] font-normal mb-[8px] text-gilroy-medium"
                    >
                      Link ({input.language}):
                    </label>
                    <InputFields
                      {...register(`link-${input.name}`)}
                      className="shadow appearance-none border rounded-2xl h-[61px] mb-[24px] mt-[8px] w-full py-4 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                      defaultValue={input.link}
                      placeholder="No link available"
                    />
                    <label
                      htmlFor={`file-${input.name}`}
                      className="text-black text-[16px] font-normal mb-[8px] text-gilroy-medium"
                    >
                      Upload File ({input.language}):
                    </label>
                    <input
                      type="file"
                      onChange={(e) => handleImageChange(e, input.name)}
                      className="shadow appearance-none border rounded-2xl h-[61px] mb-[24px] mt-[8px] w-full py-4 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                    />

                    {documentPreviews[input.name] && (
                      <img
                        src={documentPreviews[input.name]}
                        alt="Preview"
                        className="mt-4 w-10"
                      />
                    )}
                    <label
                      htmlFor={`img-${input.name}`}
                      className="text-black text-[16px] font-normal mb-[8px] text-gilroy-medium"
                    >
                      Upload Image ({input.language}):
                    </label>
                    <input
                      type="file"
                      onChange={(e) => handleImageChange(e, input.name, true)}
                      className="shadow appearance-none border rounded-2xl h-[61px] mb-[24px] mt-[8px] w-full py-4 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                    />
                    {imagePreviews[input.name] && (
                      <img
                        src={imagePreviews[input.name]}
                        alt="Preview"
                        className="mt-4 w-10"
                      />
                    )}
                  </>
                )}
                {input.section === "aboutBudsComp" && (
                  <>
                    <label
                      htmlFor={`link-${input.name}`}
                      className="text-black text-[16px] font-normal mb-[8px] text-gilroy-medium"
                    >
                      Link ({input.language}):
                    </label>
                    <InputFields
                      {...register(`link-${input.name}`)}
                      className="shadow appearance-none border rounded-2xl h-[61px] mb-[24px] mt-[8px] w-full py-4 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                      defaultValue={input.link}
                      placeholder="No link available"
                    />
                    <label
                      htmlFor={`file-${input.name}`}
                      className="text-black text-[16px] font-normal mb-[8px] text-gilroy-medium"
                    >
                      Upload File ({input.language}):
                    </label>
                    <input
                      type="file"
                      onChange={(e) => handleImageChange(e, input.name)}
                      className="shadow appearance-none border rounded-2xl h-[61px] mb-[24px] mt-[8px] w-full py-4 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                    />

                    {documentPreviews[input.name] && (
                      <img
                        src={documentPreviews[input.name]}
                        alt="Preview"
                        className="mt-4 w-10"
                      />
                    )}
                  </>
                )}
              </div>
            ))}
            <hr className="w-full bg-[#E6E6E6] mb-[24px]" />
            <ButtonComp onClose={onClose} loader={loader} />
          </div>
        </form>
      </Box>
    </Modal>
  );
};

export default EntityRegistrationOtpContent;
