import { useEffect, useState } from "react";
import {
  LineChart,
  Line,
  XAxis,
  YAxis,
  CartesianGrid,
  ResponsiveContainer,
  Tooltip,
} from "recharts";

interface TotalFoundationLineChartProps {
  intervalType: string;
}

const colors = {
  totalRegistered: "#6B3E96",
  active: "#3D90D0",
  banned: "#1C468E",
  underLitigation: "#FF5733",
};

const yearlyData = [
  {
    name: "2019",
    totalRegistered: 120,
    active: 115,
    banned: 5,
    underLitigation: 0,
  },
  {
    name: "2020",
    totalRegistered: 130,
    active: 125,
    banned: 5,
    underLitigation: 0,
  },
  {
    name: "2021",
    totalRegistered: 140,
    active: 130,
    banned: 7,
    underLitigation: 1,
  },
  {
    name: "2022",
    totalRegistered: 150,
    active: 140,
    banned: 6,
    underLitigation: 2,
  },
  {
    name: "2023",
    totalRegistered: 160,
    active: 150,
    banned: 8,
    underLitigation: 2,
  },
  {
    name: "2024",
    totalRegistered: 170,
    active: 160,
    banned: 9,
    underLitigation: 3,
  },
];

const monthlyData = [
  {
    name: "Jan",
    totalRegistered: 20,
    active: 15,
    banned: 5,
    underLitigation: 0,
  },
  {
    name: "Feb",
    totalRegistered: 30,
    active: 25,
    banned: 5,
    underLitigation: 0,
  },
  {
    name: "Mar",
    totalRegistered: 40,
    active: 35,
    banned: 5,
    underLitigation: 0,
  },
  {
    name: "Apr",
    totalRegistered: 50,
    active: 45,
    banned: 5,
    underLitigation: 0,
  },
  {
    name: "May",
    totalRegistered: 60,
    active: 55,
    banned: 4,
    underLitigation: 1,
  },
  {
    name: "Jun",
    totalRegistered: 70,
    active: 65,
    banned: 3,
    underLitigation: 2,
  },
  {
    name: "Jul",
    totalRegistered: 80,
    active: 75,
    banned: 2,
    underLitigation: 3,
  },
  {
    name: "Aug",
    totalRegistered: 90,
    active: 85,
    banned: 5,
    underLitigation: 0,
  },
  {
    name: "Sep",
    totalRegistered: 100,
    active: 95,
    banned: 1,
    underLitigation: 4,
  },
  {
    name: "Oct",
    totalRegistered: 110,
    active: 105,
    banned: 0,
    underLitigation: 5,
  },
  {
    name: "Nov",
    totalRegistered: 120,
    active: 115,
    banned: 5,
    underLitigation: 0,
  },
  {
    name: "Dec",
    totalRegistered: 130,
    active: 125,
    banned: 5,
    underLitigation: 0,
  },
];

const quarterlyData = [
  {
    name: "Q1",
    totalRegistered: 90,
    active: 75,
    banned: 15,
    underLitigation: 0,
  },
  {
    name: "Q2",
    totalRegistered: 180,
    active: 165,
    banned: 15,
    underLitigation: 3,
  },
  {
    name: "Q3",
    totalRegistered: 270,
    active: 255,
    banned: 15,
    underLitigation: 3,
  },
  {
    name: "Q4",
    totalRegistered: 360,
    active: 345,
    banned: 15,
    underLitigation: 3,
  },
];

const getDataAndTicks = (intervalType: string) => {
  switch (intervalType) {
    case "annually":
      return { data: yearlyData, ticks: [0, 50, 100, 150, 200] };
    case "quarterly":
      return { data: quarterlyData, ticks: [0, 100, 200, 300, 400] };
    case "monthly":
      return { data: monthlyData, ticks: [0, 20, 40, 60, 80, 100, 120, 140] };
    default:
      return { data: yearlyData, ticks: [0, 50, 100, 150, 200] }; // Default case
  }
};

const NewTotalFoundationLineChart: React.FC<TotalFoundationLineChartProps> = ({
  intervalType,
}) => {
  const [data, setData] = useState<typeof monthlyData>([]); // Define the type of data
  const [yAxisTicks, setYAxisTicks] = useState<number[]>([]);

  useEffect(() => {
    const { data, ticks } = getDataAndTicks(intervalType);
    setData(data);
    setYAxisTicks(ticks);
  }, [intervalType]);

  return (
    <div className="w-[100%] bg-[#E7F0FF] rounded-[24px] justify-center overflow-x-auto p-3">
      {" "}
      <h1 className="font-[700] text-[20px] ml-2">Scheme Trends over months</h1>
      <p className="mb-2 ml-2">
       &nbsp;
      </p>
      <ResponsiveContainer width="100%" height={300}>
        <LineChart data={data} className="-ml-[30px]">
          <Line
            type="monotone"
            dataKey="totalRegistered"
            stroke={colors.totalRegistered}
            strokeWidth={3}
            dot={false}
          />
          <Line
            type="monotone"
            dataKey="active"
            stroke={colors.active}
            strokeWidth={3}
            dot={false}
          />
          <Line
            type="monotone"
            dataKey="banned"
            stroke={colors.banned}
            strokeWidth={3}
            dot={false}
          />
          <Line
            type="monotone"
            dataKey="underLitigation"
            stroke={colors.underLitigation}
            strokeWidth={3}
            dot={false}
          />
          <CartesianGrid strokeDasharray="0 0" vertical={false} />
          <Tooltip />
          <XAxis
            dataKey="name"
            tickLine={false}
            axisLine={false}
            tick={{ dy: 12 }}
          />
          <YAxis ticks={yAxisTicks} axisLine={false} tickLine={false} />
        </LineChart>
      </ResponsiveContainer>
      <div className="flex items-center justify-between px-3  gap-x-1">
        <div className="flex gap-2 items-center">
          <div
            className="w-[15px] h-[15px] rounded-[50%]"
            style={{ backgroundColor: colors.totalRegistered }}
          ></div>
          <p>Registered</p>
        </div>
        <div className="flex gap-2 items-center">
          <div
            className="w-[15px] h-[15px] rounded-[50%]"
            style={{ backgroundColor: colors.active }}
          ></div>
          <p>Active</p>
        </div>
        <div className="flex gap-2 items-center">
          <div
            className="w-[15px] h-[15px] rounded-[50%]"
            style={{ backgroundColor: colors.banned }}
          ></div>
          <p>Banned</p>
        </div>
        <div className="flex gap-2 items-center">
          <div
            className="w-[15px] h-[15px] rounded-[50%]"
            style={{ backgroundColor: colors.underLitigation }}
          ></div>
          <p>Litigation</p>
        </div>
      </div>
    </div>
  );
};

export default NewTotalFoundationLineChart;
