import React from "react";

interface MenuItemProps {
  text: string;
  isActive: boolean;
  onClick: () => void;
}

const TabsItem: React.FC<MenuItemProps> = ({ text, isActive, onClick }) => {
  return (
    <li
      className={`text-nowrap text-xl py-[8px] px-[16px]  cursor-pointer ${
        isActive
          ? "bg-[#1c468e] rounded-[20px] text-[#ffffff] text-gilroy-medium"
          : "text-[#666666] text-gilroy-regular"
      }`}
      onClick={onClick}
    >
      <div className="text-gilroy-medium">{text}</div>
    </li>
  );
};

export default TabsItem;
